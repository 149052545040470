<!--
 * @Description: 练习试卷查看
 * @Author: liqin
 * @Date: 2022-02-15 15:29:11
-->
<template>
  <div>
    <!--  电脑  hidden-xs-only -->
    <el-row :gutter="10">
      <el-col :sm="24" class=" ">
        <div class="betweenCenter h3sy">
          {{ paper.papersName }}
        </div>

        <div class="positionr" v-if="isPreview && paper.isShowAchievement">
          <span>试卷得分：</span>
          <span> {{ UserInfo.userExaminationScore }}</span>
        </div>
        <el-card class="box-card">
          <div class="clearfix" v-if="remaining">
            <div class="fr" v-show="!isView">
              <span>剩余时间：</span>
              <span style="color: red">
                <TimerCountdown
                  :deadline="paper.examinationTimes * 60"
                  v-slot="time"
                  @done="examTimesEnd"
                >
                  <template v-if="time.day">{{ time.day }}天 </template>
                  <template v-if="time.hour">{{ time.hour }}时 </template>
                  <template v-if="time.minute">{{ time.minute }}分 </template>
                  <template v-if="time.second">{{ time.second }}秒 </template>
                </TimerCountdown>
              </span>
            </div>
          </div>

          <!-- <div v-for="item in topicOutLineList.slice(OutLineList,OutLineList +1)" :key="item.topicOutlineId" class="text item"> -->
          <div
            v-for="item in topicOutLineList1"
            :key="item.topicOutlineId"
            class="text item"
            style="z-index: 1"
          >
            <div class="topicOutlineName betweenJustify">
              <div class="mt10"  style="font-weight: bold">
                {{ "试题名称: " + item.topicOutlineName }}
              </div>
              <div>
                <span v-show="paper.papersShowPattern == 1"
                  >共
                  {{
                    topicOutLineList[OutLineList].questionBankList.length
                  }}
                  题，当前第 {{ BankListNum + 1 }} 题，</span
                >
                <span> 共 {{ item.totalScore }} 分</span>
              </div>
            </div>
            <!-- <div v-for="val in item.questionBankList.slice(BankListNum,BankListNum +1)" :key="val.bankId"> -->
            <div v-for="val in item.questionBankList" :key="val.bankId">
              <!-- 单选题 -->
              <div v-if="val.bankType == 0">
                <bankTypeZero
                  ref="bankTypeZero"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                  @addBankTypeZero="addBankTypeZero"
                ></bankTypeZero>
              </div>
              <!-- 多选题 -->
              <div v-if="val.bankType == 1">
                <bankTypeOne
                  ref="bankTypeOne"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                  @addBankTypeOne="addBankTypeOne"
                ></bankTypeOne>
              </div>
              <!-- 简答题 -->
              <div v-if="val.bankType == 2">
                <bankTypeTwo
                  ref="bankTypeTwo"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                  @addBankTypeTwo="addBankTypeTwo"
                ></bankTypeTwo>
              </div>
              <!-- 不定项  -->
              <div v-if="val.bankType == 3">
                <bankTypeThree
                  ref="bankTypeThree"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                  @addBankTypeThree="addBankTypeThree"
                ></bankTypeThree>
              </div>
              <!-- 填空  -->
              <div v-if="val.bankType == 4">
                <bankTypeFour
                  ref="bankTypeFour"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                  @addBankTypeFour="addBankTypeFour"
                ></bankTypeFour>
              </div>
              <!-- 判断  -->
              <div v-if="val.bankType == 5">
                <bankTypeFive
                  ref="bankTypeFive"
                  :val="val"
                  :isView="isView"
                  :userPapersId="UserInfo.userPapersId"
                  :topicOutlineId="item.topicOutlineId"
                  :isAnswer="isAnswer"
                  :isScore="isScore"
                  :paper="paper"
                  @addBankTypeFive="addBankTypeFive"
                ></bankTypeFive>
              </div>
            </div>
          </div>

          <!-- <div class="betweenCenter alignCenter h100px">

          </div> -->
          <div class="betweenCenter alignCenter h100px">
            <el-button
              type="primary"
              v-show="this.paper.papersShowPattern == 1"
              @click="PreviousStep"
              >上一题</el-button
            >
            <el-button
              type="primary"
              v-show="this.paper.papersShowPattern == 1"
              @click="NextStep"
              >下一题</el-button
            >
            <el-button
              type="primary"
              v-show="!isView"
              @click="submitPracticeUserPapersApi"
              >提交试卷</el-button
            >
             <a
              v-show="topic"
              @click="$router.go(-1)"
            >
              <!-- <el-button type="primary">返回上一页</el-button>
            </a> -->
              <el-button type="primary">回到课程</el-button>
            </a>
          </div>
          <div>
            <el-dialog
              title=""
              :visible.sync="dialogVisible"
              width="400px"
              >
              <span>确定要开始练习吗</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="practiceClick">确 定</el-button>
              </span>
            </el-dialog>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import bankTypeZero from './bankTypeZero.vue'
import bankTypeOne from './bankTypeOne.vue'
import bankTypeTwo from './bankTypeTwo.vue'
import bankTypeThree from './bankTypeThree.vue'
import bankTypeFour from './bankTypeFour.vue'
import bankTypeFive from './bankTypeFive.vue'
export default {
  components: {
    bankTypeZero,
    bankTypeOne,
    bankTypeTwo,
    bankTypeThree,
    bankTypeFour,
    bankTypeFive
  },
  data () {
    return {
      token: localStorage.getItem("pc_token")?? '',
      isAnswer: false, // 是否显示答案
      isView: false, // 是否是查看
      isScore: false, // 是否显示得分
      isPreview: true,
      topic: false, // 错题查看
      remaining: false, // 考试时间倒计时、
      dialogVisible: false,
      examInfo: {},
      examParamList: [],
      topicOutLineList: [],
      topicOutLineList1: [],
      paper: {},
      formOne: {
        examinationPassword: ''
      },
      practiceUserFillParamsStr: '',
      UserInfo: {},
      OutLineList: 0,
      BankListNum: 0,
      // 单选题
      bankTypeZeroList: [],
      bankZeronum: 0,
      // 多选题
      bankTypeOneList: [],
      bankOnenum: 0,
      // 简答题
      bankTypeTwoList: [],
      bankTwonum: 0,
      // 不定项
      bankTypeThreeList: [],
      bankThreenum: 0,
      // 判断
      bankTypeFiveList: [],
      bankFivenum: 0,
      // 填空
      bankTypeFourList: [],
      bankFournum: 0
    }
  },
  mounted () {
      
    if ('isView' in this.$route.query) {
      this.isView = true
      this.isAnswer = true
      this.isScore = true
      // 查询学员考试试题信息
      this.findPracticeUserPapersApi()
    }
    if ('isPreview' in this.$route.query) {
      // 查询学员考试试题信息
      this.findPracticeUserPapersApi()
    }
    if ('isSubmitExam' in this.$route.query) {
      // this.isView = true
      this.dialogVisible = true
      this.isPreview = false
      // 预览
      // this.startUserPracticePapersApi()
    }
  },
  methods: {
    practiceClick(){
        // 查询单个考试试题信息    
        this.findUserPracticePapersInfoApi()
        if ('isStart' in this.$route.query) {
            // 试卷点击开始考试
            this.remaining = true
            this.startUserPracticePapersApi()
        }
        this.dialogVisible = false
    },
    // 单选题
    addBankTypeZero (e) {
      if (e.subList.length == 0) {
        for (let z = 0; z < this.bankTypeZeroList.length; z++) {
          // 删除
          var index = this.bankTypeZeroList.findIndex((item) => {
            if (item.bankId === this.bankTypeZeroList[z].bankId) {
              return true
            }
          })
          this.bankTypeZeroList.splice(index, 1)
        }
      } else {
        let isFindz = false
        this.bankTypeZeroList.map(item => {
          if (item.bankId == e.bankId) {
            isFindz = true
            item = e
            return item
          }
        })
        if (!isFindz) {
          this.bankTypeZeroList.push(e)
        }
      }
    },
    // 多选题
    addBankTypeOne (e) {
      if (e.subList.length == 0) {
        for (let k = 0; k < this.bankTypeOneList.length; k++) {
          // 删除
          var index = this.bankTypeOneList.findIndex((item) => {
            if (item.bankId === this.bankTypeOneList[k].bankId) {
              return true
            }
          })
          this.bankTypeOneList.splice(index, 1)
        }
      } else {
        let isFind = false
        this.bankTypeOneList.map((item) => {
          if (item.bankId == e.bankId) {
            isFind = true
            item = e
            return item
          }
        })
        if (!isFind) {
          this.bankTypeOneList.push(e)
        }
      }
    },
    // 简答题
    addBankTypeTwo (e) {
      if (e.subList.length == '') {
        for (let i = 0; i < this.bankTypeTwoList.length; i++) {
          var index = this.bankTypeTwoList.findIndex((item) => {
            if (item.bankId === this.bankTypeTwoList[i].bankId) {
              return true
            }
          })
          this.bankTypeTwoList.splice(index, 1)
        }
      } else {
        let isFindTwo = false
        this.bankTypeTwoList.map((item) => {
          if (item.bankId == e.bankId) {
            isFindTwo = true
            item = e
            return item
          }
        })
        if (!isFindTwo) {
          this.bankTypeTwoList.push(e)
        }
      }
    },
    // 不定项
    addBankTypeThree (e) {
      if (e.subList.length == 0) {
        for (let i = 0; i < this.bankTypeThreeList.length; i++) {
          // 删除
          var index = this.bankTypeThreeList.findIndex((item) => {
            if (item.bankId === this.bankTypeThreeList[i].bankId) {
              return true
            }
          })
          this.bankTypeThreeList.splice(index, 1)
        }
      } else {
        let isFinds = false
        this.bankTypeThreeList.map((item) => {
          if (item.bankId === e.bankId) {
            isFinds = true
            item = e
            return item
          }
        })
        if (!isFinds) {
          this.bankTypeThreeList.push(e)
        }
      }
    },
    // 填空题
    addBankTypeFour (e) {
      if (e.subList.length == '') {
        for (let i = 0; i < this.bankTypeFourList.length; i++) {
          var index = this.bankTypeFourList.findIndex((item) => {
            if (item.bankId === this.bankTypeFourList[i].bankId) {
              return true
            }
          })
          this.bankTypeFourList.splice(index, 1)
        }
      } else {
        let isFindFour = false
        this.bankTypeFourList.map((item) => {
          if (item.bankId == e.bankId) {
            isFindFour = true
            item = e
            return item
          }
        })
        if (!isFindFour) {
          this.bankTypeFourList.push(e)
        }
      }
    },
    // 判断题
    addBankTypeFive (e) {
      if (e.subList.length == 0) {
        for (let f = 0; f < this.bankTypeFiveList.length; f++) {
          // 删除
          var index = this.bankTypeFiveList.findIndex((item) => {
            if (item.bankId === this.bankTypeFiveList[f].bankId) {
              return true
            }
          })
          this.bankTypeFiveList.splice(index, 1)
        }
      } else {
        let isFindf = false
        this.bankTypeFiveList.map((item) => {
          if (item.bankId == e.bankId) {
            isFindf = true
            item = e
            return item
          }
        })
        if (!isFindf) {
          this.bankTypeFiveList.push(e)
        }
      }
    },
    findUserPracticePapersInfoApi () {
      this.$api
        .findUserPracticePapersInfoApi({
          papersId: this.$route.query.papersId
        })
        .then((res) => {
          this.paper = res.data.paper
          this.examInfo = res?.data
          this.examParamList = res?.data?.fillParams ?? []        
        })
    },
    inquiryGoodsStr () {
      const List = this.examParamList.map((item) => {
        return {
          fillParamsId: item.fillParamsId,
          fillParamsName: item.fillParamsName,
          paramsFormat: item.paramsFormat,
          fillParamsValue: item.fillParamsValue,
          isRequired: item.isRequired
        }
      })
      this.practiceUserFillParamsStr = JSON.stringify(List)
    },
    startUserPracticePapersApi () {
      this.inquiryGoodsStr()
      this.$api
        .startUserPracticePapersApi({
          ...this.$route.query,
          token: this.token,
          password: this.formOne.examinationPassword || '',
          practiceUserFillParamsStr: this.practiceUserFillParamsStr
        })
        .then((res) => {
          this.topicOutLineList = res.data.topicOutLineList || []
          this.topicOutLineList1 = res.data.topicOutLineList || []

          res.data.topicOutLineList.map((item) => {
            const sub = item.questionBankList || []
            sub.map((i) => {
              if (i.bankType == 0) { // 单选题
                this.bankZeronum = this.bankZeronum + 1
                const optionList = i.option || []
                const subList = []
                optionList.map((s) => {
                  // 选中的选项
                  if (s.isCheck == 1) {
                    subList.push(s.optionId)
                  }
                })
                // 有选中的选项
                if (subList.length !== 0) {
                  const temp = {
                    bankId: i.bankId + item.topicOutlineId,
                    subList: subList
                  }
                  this.bankTypeZeroList.push(temp)
                }
              } else if (i.bankType == 1) { // 多选题
                this.bankOnenum = this.bankOnenum + 1
                const optionList = i.options || []
                const subList = []
                optionList.map((s) => {
                  // 选中的选项
                  if (s.isCheck == 1) {
                    subList.push(s.optionId)
                  }
                })
                // 有选中的选项
                if (subList.length !== 0) {
                  const temp = {
                    bankId: i.bankId + item.topicOutlineId,
                    subList: subList
                  }
                  this.bankTypeOneList.push(temp)
                }
              } else if (i.bankType == 2) { // 简答题
                this.bankTwonum = this.bankTwonum + 1
                const optionList = i.options || []
                let subList = ''
                optionList.map((s) => {
                  // 选中的选项
                  if (s.optionAnswer !== '') {
                    subList = s.optionAnswer
                  }
                })
                // 有选中的选项
                if (subList !== '') {
                  const temp = {
                    bankId: i.bankId + item.topicOutlineId,
                    subList: subList
                  }
                  this.bankTypeTwoList.push(temp)
                }
              } else if (i.bankType == 3) { // 不定向
                this.bankThreenum = this.bankThreenum + 1
                const optionList = i.options || []
                const subList = []
                optionList.map((s) => {
                  // 选中的选项
                  if (s.isCheck == 1) {
                    subList.push(s.optionId)
                  }
                })
                // 有选中的选项
                if (subList.length !== 0) {
                  const temp = {
                    bankId: i.bankId + item.topicOutlineId,
                    subList: subList
                  }
                  this.bankTypeThreeList.push(temp)
                }
              } else if (i.bankType == 4) { // 填空题
                this.bankFournum = this.bankFournum + 1
                const optionList = i.options || []
                let subList = ''
                optionList.map((s) => {
                  // 选中的选项
                  if (s.optionAnswer !== '') {
                    subList = s.optionAnswer
                  }
                })
                // 有选中的选项
                if (subList !== '') {
                  const temp = {
                    bankId: i.bankId + item.topicOutlineId,
                    subList: subList
                  }
                  this.bankTypeFourList.push(temp)
                }
              } else if (i.bankType == 5) { // 判断题
                this.bankFivenum = this.bankFivenum + 1
                const optionList = i.options || []
                let subList = 0
                optionList.map((s) => {
                  // 选中的选项
                  if (s.userOptionIsok == 1 || s.userOptionIsok == 2) {
                    subList = s.userOptionIsok
                  }
                })
                // 有选中的选项
                if (subList !== 0) {
                  const temp = {
                    bankId: i.bankId + item.topicOutlineId,
                    subList: subList
                  }
                  this.bankTypeFiveList.push(temp)
                }
              }
            })
          })
          this.paper = res.data.practicePapers || {}
          this.UserInfo = res.data.practiceUserPapers || {}
          if (this.paper.papersShowPattern == 1) {
            this.isPapersShowPattern()
          }
        }).catch((res) => {
            this.$message.warning(res.msg)
            this.remaining = false
        })
    },
    // 提交试卷
    submitPracticeUserPapersApi () {
      if (this.paper.isAnswerAll == 1) {
        const flag = false
        // 单选题
        if (this.bankZeronum !== this.bankTypeZeroList.length) {
          this.$message.error('单选不能为空')
          return
        }
        // 多选题
        if (this.bankOnenum !== this.bankTypeOneList.length) {
          this.$message.error('多选不能为空')
          return
        }
        // 简答题
        if (this.bankTwonum !== this.bankTypeTwoList.length) {
          this.$message.error('简答题不能为空')
          return
        }
        // 不定项
        if (this.bankThreenum !== this.bankTypeThreeList.length) {
          this.$message.error('不定项题不能为空')
          return
        }
        // 填空题
        if (this.bankFournum !== this.bankTypeFourList.length) {
          this.$message.error('填空题不能为空')
          return
        }
        // 判断
        if (this.bankFivenum !== this.bankTypeFiveList.length) {
          this.$message.error('判断题不能为空')
          return
        }
        if (flag) {
          this.$message('答完所有题目才能提交试卷')
          return
        }
      }
      this.$api
        .submitPracticeUserPapersApi({
          userPagersId: this.$route.query.userPapersId??this.UserInfo.userPapersId
        })
        .then((res) => {
          if (res.code == 10000) {
            this.$message.success('提交成功')
            this.isView = true
            this.topic = true
            this.UserInfo = res.data.practiceUserPapers || {}
            this.findPracticeUserPapersApi()
          }
          if ('isSubmitPrac' in this.$route.query) {
            // 试卷点击开始考试
            this.isScore = this.$route.query.isSubmitPrac == 'true'
            this.isAnswer = this.$route.query.isSubmitPrac == 'true'
          }
          // setTimeout(() => {
          //   window.close();
          // }, 1000);
        })
    },
    // 错题查看
    WrongTopicToView () {
        this.$router.push({
          path: "/course/index",
        });
    //   const routeData = this.$router.resolve({
    //     name: 'PracticeView',
    //     query: {
    //       userPapersId: this.UserInfo.userPapersId,
    //       isSubmitPrac: true
    //     }
    //   })
    //   window.open(routeData.href, '_blank')
    },
    findPracticeUserPapersApi () {
      this.$api
        .findPracticeUserPapersApi({
          userPagersId: this.$route.query.userPapersId??this.UserInfo.userPapersId
        })
        .then((res) => {
          
          // this.topicOutLineList = res.data.topicOutLineList || [];
          this.topicOutLineList = res.data.topicOutLineList || []
          this.topicOutLineList1 = res.data.topicOutLineList || []
          this.paper = res.data.practicePapers || {}
          this.UserInfo = res.data.practiceUserPapers || {}
          if (res.data.practicePapers.papersShowPattern == 1) {
            this.isPapersShowPattern()
          }
        })
    },
    // 琢题模式 ：一页显示一道试题
    isPapersShowPattern () {
      // this.topicOutLineLists = this.topicOutLineList.slice(this.OutLineList, this.OutLineList + 1)
      // console.log(this.topicOutLineLists);
      // // console.log( this.topicOutLineLists[0].questionBankList.slice(this.BankListNum, this.BankListNum + 1) );
      // this.topicOutLineLists[0].questionBankList = this.topicOutLineLists[0].questionBankList.slice(this.BankListNum, this.BankListNum + 1)
      // // .questionBankList.slice(this.BankListNum, this.BankListNum + 1) || [];
      const arr = JSON.parse(JSON.stringify(this.topicOutLineList))
      this.topicOutLineList1 = arr
        .slice(this.OutLineList, this.OutLineList + 1)
        .map((item) => {
          item.questionBankList = item.questionBankList.slice(
            this.BankListNum,
            this.BankListNum + 1
          )
          return item
        })
    },

    // 上一题
    PreviousStep () {
      console.log(
        this.BankListNum,
        this.topicOutLineList[this.OutLineList].questionBankList.length
      )
      if (this.BankListNum == 0 && this.OutLineList > 0) {
        console.log(this.OutLineList, 'OutLineList')
        this.OutLineList--
        this.BankListNum = this.topicOutLineList[
          this.OutLineList
        ].questionBankList.length
      }

      if (this.BankListNum > 0) {
        this.BankListNum--
      }
      this.isPapersShowPattern()
    },
    // 下一题
    NextStep () {
      if (
        this.BankListNum ==
          this.topicOutLineList[this.OutLineList].questionBankList.length - 1 &&
        this.OutLineList < this.topicOutLineList.length - 1
      ) {
        console.log(this.OutLineList, 'OutLineList')
        this.OutLineList++
        this.BankListNum = -1
      }
      console.log(
        this.BankListNum,
        this.topicOutLineList[this.OutLineList].questionBankList.length
      )
      if (
        this.BankListNum <
        this.topicOutLineList[this.OutLineList].questionBankList.length - 1
      ) {
        this.BankListNum++
      }
      this.isPapersShowPattern()
    },

    examTimesEnd () {}
  }
}
</script>

<style lang="scss" scoped>
.h3sy {
  margin: 0;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #18cca7;
  color: #fff;
}
::v-deep .el-card {
  margin: 0px 4%;
}

.dian {
  /* font-size: 20px; */
  margin-right: 5px;
  display: inline-block;
  width: 3px;
  height: 3px;
  background: #3ce4bc;
  position: relative;
  top: -5px;
  color: #3ce4bc;
}
.topicOutlineName {
  font-size: 20px;
}
.h100px {
  height: 100px;
}
.positionr {
  position: absolute;
  top: 16px;
  right: 7%;
  // font-size: 20px;
  color: #fff;
}
//pc端
@media only screen and (min-width: 1200px) {
 .positionr {
    font-size: 20px;
  }
  .h3sy{
    font-size: 20px;
  }
  ::v-deep .el-dialog{
    width: 35% !important;
  }
  ::v-deep .el-input--suffix .el-input__inner{
    width: 250px;
  }
}
//pad端
@media only screen and (min-width: 768px) and(max-width: 1199px) {
  .positionr {
    font-size: 20px;
  }
  .h3sy{
    font-size: 20px;
  }
  ::v-deep .el-dialog{
    width: 35% !important;
  }
  ::v-deep .el-input--suffix .el-input__inner{
    width: 250px;
  }
}
// 手机端
@media only screen and (max-width: 767px) {
  .positionr {
    font-size: 15px;
  }
  .h3sy{
    font-size: 15px;
  }
  ::v-deep .el-dialog{
    width: 90% !important;
  }
}
</style>
